<template>
  <section class="policy-list">
    <div
      class="header-title-back title-margin-bottom">
      <span
        class="title-content"
        @click="() => $router.back()">Insurer</span>
      <i
        class="el-icon-arrow-right icon-little"
      ></i>
      <span class="title-litter">{{data&&data.company_name}}</span>
    </div>
    <div class="block-body">
      <div class="flex-sb-center">
        <div class="flex block-content">
          <span
            v-if="showOtherInsurer(data.company_id)"
            class="company-logo">

            <img
              :src="getOptionData('insureOptions',data.company_id).logo"
              alt="company" />

          </span>
          <span class="is-bold">{{data&&data.company_name}}</span>
        </div>
        <span
          v-if="activeName!=2"
          class="margin-right-20">
          <v-button
            :disabled="isable"
            @click="handleExport">Export</v-button>
        </span>
      </div>
      <el-tabs
        v-model="activeName"
        @tab-click="handleClick"
      >
        <el-tab-pane
          label="Transactions"
          name="1"></el-tab-pane>
        <el-tab-pane
          label="Settlements"
          name="2"></el-tab-pane>
        <!--        <el-tab-pane-->
        <!--          label="Reports"-->
        <!--          name="3"></el-tab-pane>-->
      </el-tabs>
    </div>
    <policies-sold
      v-if="activeName==1"
      ref="polices-sold"
      :type="1"
      :summary="data.summary"
    ></policies-sold>
    <settlements
      v-if="activeName==2"
      :type="1"
    ></settlements>
    <!--    <reports-->
    <!--      v-if="activeName==3"-->
    <!--      :type="1"-->
    <!--    ></reports>-->
  </section>
</template>

<script>
import MixinOptions from '@/mixins/options'
import policiesSold from '@/views/member/componment/policiesSold'
import settlements from '@/views/member/componment/settlements'
import reports from '../member/componment/reports'

import {getInsurerDetail} from '@/api/insurer'
import {mapActions, mapState} from 'vuex'
export default {
  name: 'insurerStatement',
  components:{
    policiesSold,
    settlements,
    // reports
  },
  mixins: [MixinOptions],
  props:{
  },
  data(){

    return {
      activeName:'1',
      show:false,
      date:'',
      pageConfig: {
        //  分頁配置
        currentPage: 1,
        total: 10,
        size:20
      },
      data: {},
      tableData:[],
      isable:false
    }
  },
  computed:{
    ...mapState('statement',['statementTab'])

  },
  watch:{
    activeName:{
      immediate:true,
      handler(val){
        if(val){
          this.isable = true
        }
      }
    }
  },
  created(){
    this.activeName = this.statementTab||'1'
    this.network().getInsurerDetail()
  },
  methods:{
    ...mapActions('statement',['setStatementTab']),

    showOtherInsurer(company_id){
      let arr =  [2,3,4,5]
      return arr.includes(company_id)
    },
    handleSizeChange(size){
      this.pageConfig.size =size
    },
    handleClick() {
      this.setStatementTab(this.activeName)
      this.pageConfig.currentPage = 1
      // this.getList()
    },
    handleExport(){
      this.$refs['polices-sold'].handleExport()
    },
    network() {
      return {
        getInsurerDetail: async () => {
          const { data } = await getInsurerDetail({
            company_id:this.$route.params.companyId,
          })
          this.data = data
        },

      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/css/policyList';
.company-logo{
  width: 80px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  img{
    display: block;
    width: 100%;
  }
}
.block-body{
  background: #fff;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  .flex{
    align-items: center;
  }
  .is-bold{
    font-weight: bold;
    font-size: 24px;
    color: #000;
    margin-right: 10px;
  }
  .size-normal{
    font-size: 14px;
    color: #718096;
    margin-right: 8px;
  }
  .block-content{
    padding: 20px;
  }
  ::v-deep .el-tabs__header{
    padding-left: 20px;
    margin: 0;
    .el-tabs__nav-wrap::after{
      height: 0;
    }
  }
  .tr{
    background:#EDF2F7 ;
    margin:0 20px  20px;
    display: flex;
    span:nth-child(1){
      flex: 1;
    }
    span:nth-child(2){
      flex: 2;
    }
  }

}
.title-margin-bottom{
  margin-bottom: 20px;
  .title-content{
    font-weight: normal;
  }
  i{
    margin: 0 15px;
    font-size: 12px;
  }
}
.flex-content{
  margin-bottom: 20px;
  .title-tip{
    color: #4A5568;
    font-size: 16px;
    font-weight: 600;
    span{
      margin-right: 15px;
    }
  }
}
</style>
