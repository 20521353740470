<template>
  <section>

    <div class="show-table-maxscreen">

      <el-table
        ref="multipleTable"
        :data="tableData"
        :header-row-class-name="'table-header'"
        style="width: 100%"
      >
        <div >
          <el-table-column width="10">
          </el-table-column>
          <el-table-column
            width="280"
          >
            <template slot="header">
              <div class="table-slot-header">
                <span>Month</span>
                <v-month-picker
                  v-model="month"
                  placeholder="to"
                  type="monthrange"
                  @change="handlerChange"></v-month-picker>
              </div>
            </template>
            <template slot-scope="scope">
              <span>{{showYearMonth(scope.row.month)}}</span>
            </template>
          </el-table-column>
          <el-table-column
          >
            <template slot="header">
              <div class="table-slot-header">
                <span>Downloads</span>
                <div></div>
              </div>
            </template>
            <template  slot-scope="scope">
              <div
                v-if="type!=2"
                class="flex">
                <span
                  class="link hand margin-right-ten"
                  @click="handlerDown('issue_date',scope.row)">By Issue Date</span>
                <span
                  class="link hand"
                  @click="handlerDown('effective_date',scope.row)">By Effective Date</span>
              </div>
              <div v-else>
                <span
                  class="link hand margin-right-ten"
                  @click="handlerDown('issue_date',scope.row)">Download</span>
              </div>
            </template>
          </el-table-column>
        </div>
      </el-table>
    </div>
    <div class="show-table-minscreen">
      <div class="search-box ">
        <div
          class="search-title theme-second-blue"
          @click="show = !show">
          <div class="flex flex-center">
            <font-awesome-icon icon="filter"></font-awesome-icon>
            <span class="is-bold">Filter & Sort</span>
          </div>
          <font-awesome-icon :icon="show?'sort-up':'sort-down'"></font-awesome-icon>
        </div>
        <el-collapse-transition>
          <div
            v-show="show"
          >
            <div  class="margintop">
              <div>
                <el-row>
                  <v-row-col>
                    <span class="is-bold">Month</span>
                    <v-month-picker
                      v-model="month"
                      type="monthrange"
                      @change="handlerChange"></v-month-picker>
                  </v-row-col>
                </el-row>
              </div>
            </div>
          </div>
        </el-collapse-transition>
      </div>

      <div
        v-for="(item,index) in tableData"
        :key="index"
        class="table">
        <div
        >
          <div
            class="tr">
            <span>Month</span>
            <span>{{showHkDate(item.issue_date)}}</span>
          </div>

          <div
            class="tr">
            <span>Downloads</span>
            <span
              class="link hand margin-right-ten"
              @click="handlerDown('issue_date',item)">By Issue Date</span>
            <span
              class="link hand"
              @click="handlerDown('effective_date',item)">By Effective Date</span>

          </div>
        </div>
      </div>
    </div>
    <v-pagination
      :total="pageConfig.total"
      :current="pageConfig.currentPage"
      @handle-current-change="currentChange"
      @handle-size-change="handleSizeChange" />

  </section>
</template>

<script>
import MixinFormat from '@/mixins/format'
import MixinOptions from '@/mixins/options'
import {getReferrerReports,getReferrerRecordList, referrerReportDown, referrerRecordDown,referrerStatementDown} from '@api/referrer'
import {getInsurerReports, insurerReportDown, insurerStatementDown} from '@api/insurer'
import {getTransactionReports} from '@api/transcation'
import {downloadFile} from '@/utils/download'

export default {
  name: 'reports',
  mixins: [MixinOptions,MixinFormat],
  props:{
    type:{
      type:[Number,String],
      default:1 //1 insurer 2 referral 3 reports
    }
  },
  data(){
    return{
      show:false,
      tableData:[],
      date:'',
      pageConfig: {
        //  分頁配置
        currentPage: 1,
        total: 10,
        size:20
      },
      month:''
    }
  },
  computed:{

  },
  created(){
    this.getList()
  },
  methods:{
    getList() {
      if (this.type == 1) {
        this.network().getInsurerReports()
      } else if (this.type == 2) {
        this.network().getReferrerRecordList()
      }else{
        this.network().getTransactionReports()
      }
    },
    currentChange(page) {
      this.pageConfig.currentPage = page
      this.getList()
    },
    handleSizeChange(size) {
      this.pageConfig.size = size
      this.getList()
    },
    showOtherInsurer(company_id){
      let arr =  [2,3,4,5]
      return arr.includes(company_id)
    },
    handlerChange(){
      this.pageConfig.currentPage =1
      this.getList()
    },
    handlerDown(type,{month}){
      let params = {
        month,
        type:type
      }
      if(this.type==1){
        this.network().insurerReportDown(params)
      }else{
        this.network().referrerRecordDown(params)
      }
    },
    network(){
      return{
        getReferrerReports:async (params)=>{
          const { data } = await getReferrerReports({
            month_start:this.month?this.month[0]:'',
            month_end:this.month?this.month[1]:'',
            referral_id:this.$route.params.id,
            size:this.pageConfig.size,
            page: this.pageConfig.currentPage,
          })
          let {meta} =data
          this.tableData = data.data
          this.pageConfig.total = meta.total
        },
        getReferrerRecordList:async (params)=>{
          const { data } = await getReferrerRecordList({
            referral_id:this.$route.params.id,
            size:this.pageConfig.size,
            page: this.pageConfig.currentPage,
          })
          this.tableData = data.data
          this.pageConfig.total = data.total
        },
        getInsurerReports:async (params)=>{
          const { data } = await getInsurerReports({
            month_start:this.month?this.month[0]:'',
            month_end:this.month?this.month[1]:'',
            company_id:this.$route.params.companyId,
            size:this.pageConfig.size,
            page: this.pageConfig.currentPage,
          })
          let {meta} =data
          this.tableData = data.data
          this.pageConfig.total = meta.total
        },
        getTransactionReports:async (params)=>{
          const { data } = await getTransactionReports({
            month_start:this.month?this.month[0]:'',
            month_end:this.month?this.month[1]:'',
            size:this.pageConfig.size,
            page: this.pageConfig.currentPage,
          })
          let {meta} =data
          this.tableData = data.data
          this.pageConfig.total = meta.total
        },
        insurerReportDown: async (params) => {
          params = {
            ...params,
            company_id:this.$route.params.companyId,
          }
          let data = await insurerReportDown(params)
          downloadFile(data)
        },
        referrerReportDown: async (params) => {
          params = {
            ...params,
            referral_id:this.$route.params.id,
          }
          let data = await referrerReportDown(params)
          downloadFile(data)
        },
        referrerRecordDown: async (params) => {
          params = {
            month:params.month,
            referral_id:this.$route.params.id,
          }
          let data = await referrerRecordDown(params)
          downloadFile(data)
        }
      }
    }

  }
}
</script>
<style scoped lang="scss">
  @import '../../../assets/css/policyList';
  .flex-content{
    margin-bottom: 20px;
    .title-tip{
      color: #4A5568;
      font-size: 16px;
      font-weight: 600;
      span{
        margin-right: 15px;
      }
    }
  }
  .company-logo{
    width: 40px;
    height: 20px;
    display: flex;
    img{
      width: 100%;
      display: block;
    }
  }
</style>
